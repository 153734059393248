<template>
  <app-event event-id="superheroes" auth="login-only" :mapping="mapping">
    <template v-slot:logo>
      <v-img :src="require('./assets/SL.png')" class="ma-4" />
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";

export default {
  name: "PfizerMissionHeartPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        quest: Quest,
      },
    };
  },
};
</script>
